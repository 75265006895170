<section class="avatar-wrapper">
    <ng-container *ngIf="name" [ngTemplateOutlet]="initialsAvatar"></ng-container>
    <ng-container *ngIf="imageSrc" [ngTemplateOutlet]="imageAvatar"></ng-container>
</section>

<ng-template #initialsAvatar>
    <p-avatar [label]="displayValue" [style.background-color]="'rgba(50, 50, 50, 1)'" [style.color]="'white'" shape="circle" />
    <!--<ngx-avatars [bgColor]="bgColor" [fgColor]="fgColor" [size]="size" [name]="name"></ngx-avatars>-->
</ng-template>

<ng-template #imageAvatar>
    <!--<ngx-avatars [bgColor]="bgColor" [fgColor]="fgColor" [size]="size" [src]="imageSrc"></ngx-avatars>-->
</ng-template>
