<section class="profile-menu">
    <span class="company-name" [innerHTML]="companyName()"></span>
    <p-divider styleClass="epx-divider" layout="vertical"></p-divider>
    <epx-avatar-component [name]="user.firstname + user.lastname"></epx-avatar-component>
    <p-button data-cy="toggle-user-panel" (onClick)="op.toggle($event)" styleClass="epx-button epx-button-text">
        <epx-svg-icon [size]="32" color="Subtile" [icon]="op.overlayVisible ? 'chevron_up' : 'chevron_down'"></epx-svg-icon>
    </p-button>
    <p-popover styleClass="epx-popover epx-popover-no-gap" #op>
        <ng-container [ngTemplateOutlet]="profileMenu"></ng-container>
    </p-popover>
</section>

<ng-template #profileMenu>
    <app-epx-menu [menuItems]="menuItems"></app-epx-menu>
</ng-template>
