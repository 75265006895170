import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'refLinkPipe',
    standalone: false
})
export class RefLinkPipe implements PipeTransform {
    transform(value: string, type: 'email' | 'tel' | 'web' = 'web'): string {
        if (type === 'web') return value;
        else return `${type}:${value}`;
    }
}
