import { Component } from '@angular/core';

@Component({
    selector: 'epx-stretched-layout',
    template: `
        <section class="_stretched-layout">
            <section class="_stretched-layout-content">
                <ng-content></ng-content>
            </section>
            <section class="_stretched-layout-footer">
                <ng-content select="[footer]"></ng-content>
            </section>
        </section>
    `,
    styleUrls: ['./stretched-module-content.component.scss'],
    standalone: false
})
export class StretchedModuleContentComponent {}
