import { Pipe, PipeTransform } from '@angular/core';
import { SeverityColors } from '@shared/enum/color.enums';

@Pipe({
    name: 'voucherCountColor',
    standalone: false
})
export class VoucherCountColorPipe implements PipeTransform {
    transform(count: number | undefined): string {
        if (typeof count === 'number') return colors(count);
        return 'unset';
    }
}

const colors = (count: number) => {
    if (count === 0) return SeverityColors.error;
    if (count <= 20) return SeverityColors.warn;
    return SeverityColors.success;
};
