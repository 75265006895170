import { Component } from '@angular/core';

@Component({
    selector: 'app-epx-loader-circle',
    template: `
        <div class="loader-wrapper">
            <span class="epx-loader-circle"></span>
        </div>
    `,
    styleUrls: ['./epx-loader-circle.component.scss'],
    standalone: false
})
export class EpxLoaderCircleComponent {}
