import { Component, Input } from '@angular/core';
import { NavigationService } from '@core/services';
import { Router } from '@angular/router';

@Component({
    selector: 'app-epx-module-layout',
    templateUrl: './epx-module-layout.component.html',
    styleUrls: ['./epx-module-layout.component.scss'],
    standalone: false
})
export class EpxModuleLayoutComponent {
    @Input() headerLabel?: string;
    @Input() headerAdditionalLabel?: string | null;
    @Input() canGoBack = false;
    @Input() routerCommands?: any[];
    @Input() hasDivider = false;
    @Input() hasBackground = true;
    @Input() hasHeader = true;

    constructor(
        protected navigation: NavigationService,
        protected router: Router
    ) {}

    goBack() {
        if (!this.routerCommands) this.navigation.historyGoBack();
        if (this.routerCommands) this.router.navigate([...this.routerCommands]);
    }
}
