import { Injectable, Signal, signal } from '@angular/core';
import { BaseApiService, FileService, NavigationService, TAPiServices } from '@core/services';
import { BehaviorSubject, Observable } from 'rxjs';
import { CustomerModel } from '@modules/customer/models';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '@services/auth.service';
import { PayrollAccountNumbers, PayrollDesignationsModel } from '@shared/models';
import { MessagesService } from '@services/messages.service';
import { CookieService } from 'ngx-cookie-service';
import { COOKIE_KEYS } from '@core/config';

@Injectable({
    providedIn: 'root'
})
export class CustomerService extends BaseApiService {
    /* eslint-disable @typescript-eslint/member-ordering */
    service: TAPiServices = 'ms-customer';
    customerAPIUrl = '';
    customer: CustomerModel = new CustomerModel();
    protected _customer = new BehaviorSubject<CustomerModel>(this.customer);

    $customer = this._customer.asObservable();
    $customerName = signal<string>(this.customer.name);
    /* eslint-enable @typescript-eslint/member-ordering */

    constructor(
        protected http: HttpClient,
        public authService: AuthService,
        protected navigation: NavigationService,
        protected messages: MessagesService,
        protected fileService: FileService,
        protected cookieService: CookieService
    ) {
        super();
        this.buildUrl(this.service);
        this.customerAPIUrl = `${this.apiUrl}`;
    }

    get customerName(): Signal<string> {
        return this.$customerName.asReadonly();
    }

    deleteEmployee(id: string | undefined): Observable<boolean> {
        return this.http.delete<boolean>(`${this.customerAPIUrl}/employee/${id}`, this.options(this.authService.sessionToken()));
    }

    getCustomerById(id?: string | null) {
        const _id = id || this.customer._id;
        this.http.get<CustomerModel>(`${this.customerAPIUrl}/company/${_id}`, this.options(this.authService.sessionToken())).subscribe({
            next: this.updateCustomer.bind(this),
            error: console.error
        });
    }

    updateCustomerByID(customer: CustomerModel) {
        this.http.put<CustomerModel>(`${this.customerAPIUrl}/company/${customer._id}`, customer, this.options(this.authService.sessionToken())).subscribe({
            next: this.updateCustomer.bind(this),
            error: console.error
        });
    }

    getDesignationConfig(): Observable<PayrollDesignationsModel[]> {
        return this.http.get<PayrollDesignationsModel[]>(`${this.customerAPIUrl}/configs/company/designations`, this.options(this.authService.sessionToken()));
    }

    updateCustomerDesignations(data: PayrollAccountNumbers) {
        return this.http.put<PayrollAccountNumbers>(`${this.customerAPIUrl}/customer/designations`, data, this.options(this.authService.sessionToken())).subscribe({
            next: () => {
                this.messages.showSuccess();
                this.getCustomerById();
            },
            error: console.error
        });
    }

    protected updateCustomer(customer: CustomerModel) {
        if (customer._id) {
            this.cookieService.set(COOKIE_KEYS.CUSTOMER_ID, customer._id);
            this.customer = customer;
            this._customer.next(customer);
            this.$customerName.set(customer.name);
        }
    }
}
