import { Component, OnDestroy } from '@angular/core';
import { LayoutViewModel } from '@layout/dashboard-layout/layout.view.model';
import { UiService } from '@services/ui.service';
import { UserService } from '@services/user.service';

@Component({
    selector: 'app-dashboard-layout',
    templateUrl: './dashboard-layout.component.html',
    styleUrls: ['./dashboard-layout.component.scss'],
    standalone: false
})
export class DashboardLayoutComponent implements OnDestroy {
    viewModel: LayoutViewModel = new LayoutViewModel();

    constructor(
        private uiService: UiService,
        private userService: UserService
    ) {
        this.viewModel.initialState(this.uiService.$sidebarStateUpdated, this.userService.$user);
    }

    toggleSidebar() {
        this.viewModel.sidebarOpen = !this.viewModel.sidebarOpen;
        this.uiService.updateSidebarState(this.viewModel.sidebarOpen);
    }

    ngOnDestroy() {
        this.viewModel.destroy();
    }
}
