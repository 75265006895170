import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TInternalBenefitCategory } from '@shared/types';

@Pipe({
    name: 'internalCategoryName',
    standalone: false
})
export class InternalCategoryNamePipe implements PipeTransform {
    constructor(private translate: TranslateService) {}

    transform(value: TInternalBenefitCategory): string {
        const uValue = value.toUpperCase() || 'EMPTY';
        return this.translate.instant(`MODULE_BENEFIT.BENEFIT_CATEGORIES.${uValue}`);
    }
}
