import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BenefitDescriptionsModel } from '@modules/benefit/models/benefit-descriptions.model';

@Pipe({
    name: 'epxDescriptionKey',
    standalone: false
})
export class DescriptionKeyPipe implements PipeTransform {
    constructor(private translate: TranslateService) {}

    transform(key: keyof BenefitDescriptionsModel): string {
        const tKey = key.toUpperCase() || 'No Key';
        return this.translate.instant(`MODULE_BENEFIT.GENERAL.DESCRIPTIONS.${tKey}`);
    }
}
