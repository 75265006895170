export function stringIsEmpty(string) {
  return typeof string !== 'string' || !string;
}
export function capitalize(string) {
  if (stringIsEmpty(string.trim())) {
    return string;
  }
  const firstChar = string.charAt(0);
  string = string.substring(1);
  string = firstChar.toUpperCase() + string;
  return string;
}
export function deCapitalize(string) {
  if (stringIsEmpty(string.trim())) {
    return string;
  }
  const firstChar = string.charAt(0);
  string = string.substring(1);
  string = firstChar.toLowerCase() + string;
  return string;
}
export function pluralize(string) {
  let pluralChar = 's';
  if (stringIsEmpty(string.trim())) {
    return string;
  }
  const lastChar = string.charAt(string.length - 1);
  switch (lastChar) {
    case 's':
    case 'x':
      pluralChar = 'es';
      break;
    case 'y':
      string = string.substring(0, string.length - 1);
      pluralChar = 'ies';
      break;
    default:
      break;
  }
  return string + pluralChar;
}
export function stripString(value, allowedChars, caseSensitive = false) {
  if (stringIsEmpty(value)) return value;
  if (!Array.isArray(allowedChars)) allowedChars = allowedChars.split('');
  if (!caseSensitive) {
    allowedChars = allowedChars.map(i => i.toLowerCase()).concat(allowedChars.map(i => i.toUpperCase()));
  }
  return value.split('').filter(char => allowedChars.indexOf(char) >= 0).join('');
}
export function numberOfMatches(value, expression, caseSensitive = false) {
  if (stringIsEmpty(value)) return 0;
  if (typeof expression === 'string') {
    expression = new RegExp(expression, caseSensitive ? 'g' : 'ig');
  }
  return (value.match(expression) || []).length;
}
export function boolFromString(value, trim = true) {
  if (stringIsEmpty(value)) return;
  value = trim ? value.trim() : value;
  switch (value.toLowerCase()) {
    case 'no':
    case 'false':
    case '0':
      return false;
    case 'yes':
    case 'true':
    case '1':
      return true;
    default:
      return;
  }
}
