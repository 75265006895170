import { Pipe, PipeTransform } from '@angular/core';
import { orderBy } from 'lodash-es';

@Pipe({
    name: 'sortByPipe',
    standalone: false
})
export class SortByPipe implements PipeTransform {
    transform(value: any[], sortBy: any, order: 'asc' | 'desc' = 'asc'): any[] {
        return orderBy(value, sortBy, order);
    }
}
