import { Component, Input } from '@angular/core';
import { AvatarInitialsUtil } from '@shared/utils/avatar.util';

@Component({
    selector: 'epx-avatar-component',
    templateUrl: 'epx-avatar.component.html',
    styleUrls: ['epx-avatar.component.scss'],
    standalone: false
})
export class EpxAvatarComponent {
    @Input() name?: string;
    @Input() imageSrc?: string;
    @Input() size = 40;
    @Input() bgColor = '#323232';
    @Input() fgColor = '#ffffff';
    avatarUtil: AvatarInitialsUtil = new AvatarInitialsUtil();

    get displayValue(): string {
        return this.avatarUtil.getAvatar(this.name ?? '', 2);
    }
}
