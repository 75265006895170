<section>
    <ng-container *ngIf="applications">
        <ng-container *ngTemplateOutlet="subsidyTable"></ng-container>
    </ng-container>
</section>

<ng-template #subsidyTable>
    <p-table
        [sortOrder]="-1"
        scrollHeight="65vh"
        [scrollable]="true"
        #applicationTable
        selectionMode="single"
        styleClass="epx-table"
        [columns]="tableColumns"
        [value]="applications"
        [globalFilterFields]="['name', 'applicant.firstname', 'applicant.lastname', 'status', 'applicant.fullName']">
        <ng-template pTemplate="caption">
            <section class="flex flex-row justify-content-start align-items-center gap-3">
                <p-button (onClick)="toggleFilter.emit()" styleClass="epx-button epx-icon-button epx-button-icon-left epx-button-secondary" [label]="'GENERAL.FILTER' | translate" iconPos="left">
                    <epx-svg-icon [color]="filterActive ? 'Secondary' : 'Primary'" icon="filter"></epx-svg-icon>
                </p-button>
                <div class="epx-input-field p-input-icon-left p-input-icon-right">
                    <span class="epx-input-addition">
                        <epx-svg-icon icon="search"></epx-svg-icon>
                    </span>
                    <input
                        #filterField
                        class="epx-input epx-input-variant"
                        pInputText
                        type="text"
                        (input)="applyFilterGlobal(applicationTable, $event, 'contains')"
                        [placeholder]="'MODULE_SUBSIDY.APPLICATION.ACTIONS.SEARCH_FOR_APPLICATION' | translate" />
                    <span class="epx-input-addition">
                        <p-button styleClass="epx-button epx-button-text" *ngIf="filterField.value" (onClick)="clear(applicationTable, filterField)">
                            <epx-svg-icon [size]="18" color="Subtile" icon="plus" rotation="45deg"></epx-svg-icon>
                        </p-button>
                    </span>
                </div>
            </section>
        </ng-template>
        <ng-template pTemplate="header" let-columns>
            <tr>
                <th [pSortableColumn]="col.field" [style.width.%]="col.width" *ngFor="let col of columns">
                    <span [innerHTML]="col.header | translate"></span>
                    <p-sortIcon class="ml-2" *ngIf="col.sortable" [field]="col.field"></p-sortIcon>
                </th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-application let-columns="columns" let-rowgroup="rowgroup" let-rowspan="rowspan">
            <tr (click)="selectApplication.emit(application)" [class.font-bold]="application.status === 'new'">
                <td [innerHTML]="application.applicant.fullName"></td>
                <td [innerHTML]="application.createdAt | epxDateFromMillis"></td>
                <td [innerHTML]="application.updatedAt ? (application.updatedAt | epxDateFromMillis) : '-'"></td>
                <td [innerHTML]="'MODULE_BENEFIT.BENEFIT_SUBSIDY_TYPE.' + application.subsidyType.toUpperCase() | translate"></td>
                <td [innerHTML]="application.subsidyAmount | epxCurrency"></td>
                <td>
                    <div class="flex justify-content-end align-items-center">
                        <app-application-status-tag [status]="application.status"></app-application-status-tag>
                    </div>
                </td>
            </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
            <ng-container *ngTemplateOutlet="noTableData"></ng-container>
        </ng-template>
    </p-table>
</ng-template>

<ng-template #noTableData>
    <div class="empty p-5">
        <span>No Data Found</span>
    </div>
</ng-template>
