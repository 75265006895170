import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-epx-pre-loader',
    template: `<section class="_preloader-wrapper" [ngClass]="mode">
        <div class="_loader">
            <ng-content></ng-content>
        </div>
        <span class="loader-text" [innerHTML]="loaderText"></span>
    </section>`,
    styleUrls: ['./epx-pre-loader.component.scss'],
    standalone: false
})
export class EpxPreLoaderComponent {
    @Input() loaderText = 'Lädt ...';
    @Input() mode: 'light' | 'dark' = 'dark';
}
