import { Component, Input } from '@angular/core';

export type TJustify = 'justify-content-start' | 'justify-content-end' | 'justify-content-between' | 'justify-content-center' | 'justify-content-around' | 'justify-content-evenly';

@Component({
    selector: 'app-epx-button-stack',
    template: `
        <section [ngClass]="[justify]" class="flex flex-row gap-2">
            <ng-content></ng-content>
        </section>
    `,
    standalone: false
})
export class EpxButtonStackComponent {
    @Input() justify: TJustify = 'justify-content-start';
}
