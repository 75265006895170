import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
    name: 'epxPluralization',
    pure: false,
    standalone: false
})
export class EpxPluralizationPipe implements PipeTransform {
    constructor(protected translate: TranslateService) {}

    transform(key: string, number: number = 1): string {
        const term = number > 1 ? 'PLURAL' : 'SINGULAR';
        return this.translate.instant(`${key}.${term}`);
    }
}
