import { Directive, ElementRef, Input } from '@angular/core';
import { DateTime } from 'luxon';

@Directive({
    selector: '[epxEndOfMonth]',
    standalone: false
})
export class EpxEndOfMonthDirective {
    @Input() date?: number;

    constructor(private el: ElementRef) {
        const DATE = this.date ? DateTime.fromMillis(this.date) : DateTime.now();
        this.el.nativeElement.innerHTML = DATE.endOf('month').toFormat('dd.MM.yy');
    }
}
