import { Pipe, PipeTransform } from '@angular/core';
import { TIconType } from '@shared/components/ui';

@Pipe({
    name: 'epxSvgIconPath',
    standalone: false
})
export class EpxSvgUrlPipe implements PipeTransform {
    transform(iconName: TIconType, iconSet: 'ui' | 'brand' = 'ui'): any {
        return `assets/images/${iconSet}/icons/${iconName}.svg`;
    }
}
