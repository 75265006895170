import { Component, Input } from '@angular/core';
import { appLinks } from '@shared/constants';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss'],
    standalone: false
})
export class FooterComponent {
    @Input() hasDivider = true;
    footerLinks: { link: string; label: string; id: string }[] = [
        {
            id: 'footer-link-terms-of-service',
            label: 'AGB',
            link: appLinks.TermsAndConditions
        },
        {
            id: 'footer-link-privacy-policy',
            label: 'Datenschutz',
            link: appLinks.PrivacyPolicy
        },
        {
            id: 'footer-link-impressum',
            label: 'Impressum',
            link: appLinks.LegalNoticeUrl
        }
    ];
}
