import { HttpParams } from '@angular/common/http';
import { environment } from 'environments/environment';
import { stringIsEmpty } from '@sbfw/core';

export type TAPiServices = 'ms-customer' | 'ms-benefits' | 'ms-user' | 'ms-files' | 'ms-purchase';

export interface IAPIHTTPHeaderOptions {
    [header: string]: string | string[];
}

export interface IAPIHTTPOptions {
    headers?: IAPIHTTPHeaderOptions;
    reportProgress?: boolean;
    observe?: 'body';
    params?: HttpParams;
}

export class BaseApiService {
    protected environment: string | null = null;
    protected apiUrl = '';

    protected getApiUrl(service: TAPiServices): string {
        const env = environment;
        if (env.name === 'local') return `${env.apiProtocol}${env.apiUrl}${service}${env.apiVersion}`;
        return `${env.apiProtocol}${service}${env.apiSubPath}${env.apiDomain}${env.apiVersion}`;
    }

    protected buildUrl(service: TAPiServices) {
        const env = environment;
        if (env.name === 'local') this.apiUrl = `${env.apiProtocol}${env.apiUrl}${service}${env.apiVersion}`;
        else this.apiUrl = `${env.apiProtocol}${service}${env.apiSubPath}${env.apiDomain}${env.apiVersion}`;
    }

    protected headers(token: string, headers?: IAPIHTTPHeaderOptions): IAPIHTTPHeaderOptions {
        headers = headers || {};
        if (!stringIsEmpty(token)) headers['x-session-token'] = token;
        return headers;
    }

    protected options(token: string | null, options?: IAPIHTTPOptions): IAPIHTTPOptions {
        options = options || {};
        if (token) options.headers = this.headers(token, options.headers);
        return options;
    }
}
