import { BaseApiModel } from '@shared/models/base-api.model';

type TApplicationAttachmentType = 'initial' | 'receipt';

class FileAttachment extends BaseApiModel {
    downloadPath = '';
    lastChanged?: number;
    mimeType: any;

    name = '';
    storageName = '';
    size?: number;
    type?: TApplicationAttachmentType;
    progress = 0;
    downLoading = false;
    /// @deprecated
    downloadURL = '';
}

export { FileAttachment };
