import { Component, Input } from '@angular/core';
import { EmployeeVoucherModel } from '@modules/benefit/models';
import { DateTime } from 'luxon';
import { EmployeeModel } from '@shared/models';
import { getCurrencySymbol } from '@angular/common';

interface IDataObject {
    [key: string]: string | number | boolean | null;
}

@Component({
    selector: 'app-voucher-history',
    templateUrl: './voucher-history.component.html',
    styleUrls: ['./voucher-history.component.scss'],
    standalone: false
})
export class VoucherHistoryComponent {
    @Input() employee: EmployeeModel = new EmployeeModel();
    @Input() voucherDisplay?: {
        grouped: { [key: string]: EmployeeVoucherModel[] };
        monthList: string[];
        old: { grouped: { [key: string]: EmployeeVoucherModel[] }; monthList: string[] };
    };

    downloadCSV(event: Event, month: string) {
        event.stopPropagation();
        console.log('Handle CSV Export^', month);
        // const fileName = `${this.employee.email}-${month}-vouchers`;
        // const vouchers = this.voucherDisplay?.grouped[month];
        // const dataExport = this.genCSVData(vouchers || []);
        //this.csvService.toCsv(dataExport, fileName);
    }

    genCSVData(vouchers: EmployeeVoucherModel[]): IDataObject[] {
        if (!this.voucherDisplay?.grouped) return [];
        return vouchers.map((v) => ({
            Datum: DateTime.fromMillis(v.purchasedAt).toFormat('dd.MM.yyyy'),
            Partner: v.benefitName,
            Wert: `${v.amount.value} ${getCurrencySymbol(v.amount.currencyCode, 'narrow')}`,
            Code: v.code
        }));
    }
}
